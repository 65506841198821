import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import "./header.css";
import {Auth} from "./auth/Auth";
import {useDispatch, useSelector} from "react-redux";
import {MenuHeader} from "../../sections/menu/Menu";
import {Link, useHistory} from "react-router-dom";
import useResize from "../../Constants/useResize";
import ads from "../../sections/menu/assets/ads-list.svg";
import bellMenu from "../header/assets/bell-menu.svg";
import archive from "../../sections/menu/assets/archive.svg";
import settings from "../../sections/menu/assets/settings.svg";
import logout from "../../sections/menu/assets/logout.svg";
import heartMenu from "../../sections/menu/assets/heart.svg";
import creditCard from "../../sections/menu/assets/credit-card.svg";
import message from "../../sections/menu/assets/message-circle-2.svg";
import PopupLogout from "../../Components/listMenu/PopupLogout/PopupLogout";
import {Search} from "./search/Search";
import SearchMobile from "./searchMob/SearchMobile"
import OutsideClickHandler from '../../Constants/OutsideClickHandler';
import {
    getFollowedAdsCount, getUserAvatar, getUserName, newNotificationsData,
    getUserId, getUnreadMessagesCount, getUnreadNotificationsData,
} from "../../redux/selectors/selectors";
import {getFollowedAds} from "../../redux/reducers/ads/sliceAds";
import Notifications from "../notifications/Notifications";
import {APIUserConversations} from "../../API/api";
import useNotifications from "../../Constants/useNotifications";
import {checkNewMessages, updateNotificationData, setNewNotifications} from "../../redux/reducers/chat/sliceChat";
import useSocket from "../../Constants/useSocket";
import {capitalize} from "lodash";
import placeholderImage from "../userArchive/assets/images/placeholder_ad_image.svg";
import logo from "./assets/logo-w-sm.webp";

export const Header = () => {
    const {t} = useTranslation();
    const {notifications} = useNotifications();
    const {socket} = useSocket();
    const userId = useSelector(getUserId);
    const isLogin = useSelector((state) => state.userAuth.isLogin);
    const history = useHistory();
    const dispatch = useDispatch();
    const followedAdsCount = useSelector(getFollowedAdsCount);
    const [bells, setBells] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const {innerWidth} = useResize();
    const [userPrivateInfo, setUserPrivateInfo] = useState({});
    const [isClosedByOutsideClick, setIsClosedByOutsideClick] = useState(false);
    const [modalActiveLogout, setModalActiveLogout] = useState(false);
    const [openSearchBar, setOpenSearchBar] = useState(false)
    const [barOpened,] = useState(false);
    const userName = useSelector(getUserName);
    const userAvatar = useSelector(getUserAvatar);
    const messagesCount = useSelector(getUnreadMessagesCount);
    const notificationsData = useSelector(getUnreadNotificationsData);
    const currentNotificationsData = useSelector(newNotificationsData);
    const [notificationsInfo, setNotificationsInfo] = useState({
        show: false,
        data: [],
    });

    const menuItemsMob = [
        {
            url: `/profile/my-ads?page=1`,
            src: ads,
            alt: "ads",
            text: t('my-ads-list'),
        },
        {
            url: `/profile/messages`,
            src: message,
            alt: "messages",
            text: "Messages",
            badge: messagesCount
        },
        {
            url: `/profile/my-followed?page=1`,
            src: heartMenu,
            alt: "followed",
            text: "Followed Ads",
            badge: followedAdsCount
        },
        {
            url: `/profile/my-archive?page=1`,
            src: archive,
            alt: "archive",
            text: "Archive",
        },
        {
            url: `/profile/my-invoices?page=1`,
            src: creditCard,
            alt: "payment",
            text: "Payment and transactions",
        },
        {
            url: `/profile/my-settings/main-info`,
            src: settings,
            alt: "settings",
            text: "Settings",
        },
    ];


    useEffect(() => {
        if (isLogin) {
            let pageNumber = new URLSearchParams(window.location.search).get("page");
            let paramsData = {
                page_size: 100,
            }
            if (pageNumber) {
                paramsData.page = +pageNumber
            }
            dispatch(getFollowedAds(paramsData))
        }
    }, [dispatch, isLogin]);

    const togglePopup = () => setModalActiveLogout(!modalActiveLogout);

    const handleLoginAuth = (type) => {
        history.push(`/auth?type=${type}`);
        closeBurger()
    };

    const toggleNotifications = () => {
        setNotificationsInfo(prevState => ({
            ...prevState,
            show: !prevState.show
        }));
    }

    const burgerToggle = () => setNavOpen(!navOpen);
    const closeBurger = () => setNavOpen(false);

    useEffect(() => {
        if (userId) {
            APIUserConversations.getUserPublicInfo(userId)
                .then(res => {
                    // console.log(res)
                    setUserPrivateInfo(res.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [userId]);

    useEffect(() => {
        if (notifications) {
            notifications.on("notification", (data) => {
                console.log(data);
                // if (data.event_name === 'ad_created' && userId === data.ad.user.id) {
                //     return
                // }

                dispatch(setNewNotifications([
                    !!data.ad ? {
                        ...data,
                        ad: {
                            ...data.ad,
                            title: data.ad.title.trim()
                        },
                    } : data,
                    ...currentNotificationsData]))
            });
        }
    }, [notifications, dispatch, currentNotificationsData, userId])

    useEffect(() => {
        if (isLogin) {
            dispatch(checkNewMessages())
        }
    }, [dispatch, isLogin])

    useEffect(() => {
        if (socket) {
            socket.on("message", (data) => {
                dispatch(checkNewMessages())
                dispatch(setNewNotifications([
                    {
                        ...data,
                        event_name: 'message',
                        ad: {
                            ...data.ad,
                            title: data.ad.title.trim()
                        },
                    },
                    ...currentNotificationsData]))
            });
        }
    }, [socket, dispatch, currentNotificationsData])

    const closeMobileNotifications = () => {
        setNotificationsInfo(prevState => ({
            ...prevState,
            show: false,
        }))
        dispatch(setNewNotifications([]))
        dispatch(updateNotificationData([...currentNotificationsData, ...notificationsData]))
    }

    const notificationsLayout = (mobileClick) => {
        const imageView = (el, alt) => {
            let src = (format) => el.ad.images && el.ad.images.length ? `https://d2j7v9zcmivqbu.cloudfront.net/${el.ad.user.id}/${el.ad.images[0]}.${format}` : placeholderImage;
            return (
                <picture>
                    <source srcSet={src('webp')} type={"image/webp"}/>
                    <img src={src('jpeg')} alt={alt ?? 'ad updated'}/>
                </picture>
            )
        }

        const setHandleNotifications = (updatedNewArray, updatedEarlierArray) => {
            setBells(false)
            if (mobileClick) {
                setNotificationsInfo(prevState => ({
                    ...prevState,
                    show: false,
                }))
                setNavOpen(false)
            }
            dispatch(setNewNotifications([]))
            dispatch(updateNotificationData([...updatedNewArray, ...updatedEarlierArray]))
        }

        const newEarlierNotification = (notificationsArray, earlierArray) => {
            return (
                notificationsArray.length
                    ? notificationsArray.map((el, i) => {
                        if (el.event_name === 'ad_updated') {
                            return (
                                !el.ad.archived
                                    ? <Link to={`/ads/detail-ad/${el.ad.id}`}
                                            onClick={() => {
                                                setHandleNotifications(currentNotificationsData.filter(a => a.ad.id !== el.ad.id), earlierArray ? notificationsData.filter(a => a.ad.id !== el.ad.id) : notificationsData)

                                            }}
                                            key={i} className={'notifications-about'}>
                                        <div className={'sender_avatar'}>
                                            {imageView(el)}
                                        </div>
                                        <span><b>{capitalize(el.ad.title)}</b> {t('notification.ad-changed')}</span>
                                    </Link>
                                    : <div key={i} className={'notifications-about'}>
                                        <div className={'sender_avatar'}>
                                            {imageView(el)}
                                        </div>
                                        <span>Archived <b>{capitalize(el.ad.title)}</b> {t('notification.ad-changed')}</span>
                                    </div>
                            )
                        } else if (el.event_name === 'message') {
                            return (
                                <Link to={`/profile/messages/${el.conversation}`}
                                      onClick={() => {
                                          setHandleNotifications(currentNotificationsData.filter(a => a.conversation !== el.conversation), earlierArray ? notificationsData.filter(a => a.conversation !== el.conversation) : notificationsData)
                                      }}
                                      key={i}
                                      className={'notifications-about'}>
                                    <div className={'sender_avatar'}>
                                        {el.sender.avatar
                                            ? <img src={el.sender.avatar} alt={'customer'}/>
                                            : <span>{el.sender.name.charAt(0)}</span>}
                                    </div>
                                    <span><b>{capitalize(el.sender.name)}</b> {t('notification.new-message')}
                                        <b>{capitalize(el.ad.title)}</b></span>
                                </Link>
                            )
                        } else if (el.event_name === 'payment_received') {
                            return (
                                <div key={i} className={'notifications-about'}>
                                    <div style={{background: 'none'}} className={'sender_avatar'}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.2001 7.00003C16.3562 6.03536 15.2869 5.38284 14.1264 5.12429C12.9659 4.86573 11.7658 5.01265 10.6766 5.54663C9.5874 6.08061 8.65755 6.97787 8.00367 8.1259C7.34979 9.27394 7.00098 10.6216 7.00098 12C7.00098 13.3784 7.34979 14.7261 8.00367 15.8742C8.65755 17.0222 9.5874 17.9195 10.6766 18.4534C11.7658 18.9874 12.9659 19.1343 14.1264 18.8758C15.2869 18.6172 16.3562 17.9647 17.2001 17"
                                                stroke="#20C576" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M5 14H13M13 10H5H13Z" stroke="#20C576" strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <span>{t('notification.payment-received')} <b>{el.amount} {el.currency}</b></span>
                                </div>
                            )
                        } else {
                            return (
                                !el.ad.archived
                                    ? <Link to={`/ads/detail-ad/${el.ad.id}`}
                                            onClick={() => {
                                                setHandleNotifications(currentNotificationsData.filter(a => a.ad.id !== el.ad.id), earlierArray ? notificationsData.filter(a => a.ad.id !== el.ad.id) : notificationsData)
                                            }}
                                            key={i} className={'notifications-about'}>
                                        <div className={'sender_avatar'}>
                                            {imageView(el, 'ad created')}
                                        </div>
                                        <span><b>{capitalize(el.ad.title)}</b> {t('notification.ad-created')}</span>
                                    </Link>
                                    : <div key={i} className={'notifications-about'}>
                                        <div className={'sender_avatar'}>
                                            {imageView(el, 'ad created')}
                                        </div>
                                        <span><b>{capitalize(el.ad.title)}</b> {t('notification.ad-created')}</span>
                                    </div>
                            )
                        }
                    }) : <div className={'notifications-about'}>
                        <span>{t('no-notifications-yet')}</span>
                    </div>
            )
        }

        return (
            <div className={'content'}>
                <div className={'earlier_notification'}>
                    {newEarlierNotification(currentNotificationsData)}
                </div>
                {!!notificationsData.length
                    ? <div className={'older_notification'}>
                        <span>{t('Earlier')}:</span>
                        {newEarlierNotification(notificationsData, true)}
                    </div> : null}
            </div>
        )
    }

    return (
        <div className={`hero-header`}>
            <nav className={'nav-bar-header'}>
                <div className={"burger-and-bazar"}>
                    {innerWidth && innerWidth <= 830 && (
                        <div onClick={burgerToggle}
                             className={`${barOpened ? "burger-no-active" : "burger-menu-wrapper"}`}>
                            <div
                                className={`burger-menu ${navOpen ? "active" : ""}`}>
                                <div/>
                                <div/>
                                <div/>
                            </div>
                            {!!currentNotificationsData.length && !navOpen ?
                                <div className={'burger-new-notification'}/> : null}
                        </div>
                    )}

                    <div className={`nav-sidebar ${navOpen ? "active" : ""}`}>
                        <span className={"main-word"}>{`${process.env.REACT_APP_TITLE}`}</span>
                        <span className={"hyperspace"}>{t('page-slogan')}</span>
                        <button
                            className={"sign-up"}
                            onClick={() => handleLoginAuth("sign-up")}
                        >
                            {t('sign-up')}
                        </button>
                        <button
                            className={"log-in"}
                            onClick={() => handleLoginAuth("login")}
                        >
                            {t('log-in')}
                        </button>
                    </div>
                    <Link to={`/`} onClick={closeBurger}>
                        <div className={barOpened ? "main-no-active" : "main-logo"}>
                            <img style={{width: 40, height: 40, borderRadius: "100%"}} src={logo} alt={"logo"}/>
                        </div>
                    </Link>

                    {!openSearchBar && innerWidth <= 830 ?
                        (
                            isLogin ?
                                <Link to={`/createAd`} className={"createAd-button-mobile"}>
                                    <span>{t('create-ad')}</span>
                                </Link> : <Link to={`/auth?type=login`} className={"createAd-button-mobile"}>
                                    <span>{t('create-ad')}</span>
                                </Link>) : null}

                </div>
                {innerWidth && innerWidth > 830 ? (
                    <Search className={'searchName'}/>
                ) : (
                    <SearchMobile openSearchBar={openSearchBar}
                                  setOpenSearchBar={setOpenSearchBar}
                                  setNavOpen={setNavOpen}
                    />
                )}
                {isLogin ? (
                    <div className={`nav-sidebar ${navOpen ? "active" : ""}`}>
                        <div className={"mob-menu-islog"}>
                            <div className={'person-createAd'}>
                                <div className={"person-header"}>
                                    <img src={userAvatar} alt={"person"} className={"person-img"}/>
                                    <span className={"person-name"}>{userName}</span>
                                </div>
                                <Link to={`/createAd`} className={"createAd"} onClick={burgerToggle}>{t('create-ad')}</Link>
                            </div>
                            <div className={"menu-bar"}>
                                {!!userPrivateInfo.rating ? (
                                    <Link to={'/profile/ratings'} onClick={burgerToggle}>
                                        <div className={"my-rate"}>
                                            <svg
                                                width="24"
                                                height="25"
                                                viewBox="0 0 24 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12.0001 18.25L5.82808 21.495L7.00708 14.622L2.00708 9.75495L8.90708 8.75495L11.9931 2.50195L15.0791 8.75495L21.9791 9.75495L16.9791 14.622L18.1581 21.495L12.0001 18.25Z"
                                                    fill="#FFB648"
                                                    stroke="#FFB648"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div>
                                                <span className={"title"}> {t('my-rate')}</span>
                                                {!!userPrivateInfo.rating ?
                                                    <span
                                                        className={"rating_num"}>( {userPrivateInfo.rating?.toFixed(1)} / 5.0 )</span>
                                                    :
                                                    <span style={{
                                                        color: '#94A3B8',
                                                        marginLeft: "10px"
                                                    }}>t('not-rated-yet')</span>}
                                            </div>
                                        </div>
                                    </Link>
                                ) : (
                                    <div className={"my-rate"}>
                                        <svg
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.0001 18.25L5.82808 21.495L7.00708 14.622L2.00708 9.75495L8.90708 8.75495L11.9931 2.50195L15.0791 8.75495L21.9791 9.75495L16.9791 14.622L18.1581 21.495L12.0001 18.25Z"
                                                fill="#FFB648"
                                                stroke="#FFB648"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div>
                                            <span className={"title"}> {t('my-rate')}</span>

                                            {!!userPrivateInfo.rating ?
                                                <span
                                                    className={"rating_num"}>( {userPrivateInfo.rating?.toFixed(1)} / 5.0 )</span>
                                                :
                                                <span
                                                    style={{
                                                        color: '#94A3B8',
                                                        marginLeft: "10px"
                                                    }}>{t('not-rated-yet')}</span>}
                                        </div>
                                    </div>
                                )}
                                <div onClick={toggleNotifications} className={'my-menu'}>
                                    <img src={bellMenu} alt={'notifications'}/>
                                    <span className={"title"}>{t('notifications')}</span>
                                    {!!currentNotificationsData.length ?
                                        <span
                                            className={"notifications_mob_number"}>{currentNotificationsData.length}</span> : null}
                                </div>
                                <div
                                    className={`notifications_menu ${notificationsInfo.show ? "active" : ""}`}>
                                    <Notifications notificationsInfo={notificationsInfo}
                                                   closeMobileNotifications={closeMobileNotifications}
                                                   notificationsLayout={notificationsLayout}
                                                   setNotificationsInfo={setNotificationsInfo}/>
                                </div>
                                {menuItemsMob.map((el, i) => (

                                    <Link to={el.url}
                                          className={"my-menu"}
                                          key={i}
                                          onClick={burgerToggle}
                                    >
                                        <img src={el.src} alt={el.alt}/>
                                        <span className={"title"}>{t(el.text)}</span>
                                        {el.badge ?
                                            <span
                                                className={"notifications_mob_number"}>{el.badge}</span> : null}

                                    </Link>

                                ))}
                                <div className={"my-menu-settings"} onClick={togglePopup}>
                                    <img src={logout} alt={"logout"}/>
                                    <span className={"title"}>{t('logout')}</span>
                                </div>
                                {modalActiveLogout && (
                                    <PopupLogout
                                        active={modalActiveLogout}
                                        setActive={setModalActiveLogout}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}

                {isLogin && innerWidth && innerWidth > 830 ? (
                    <>
                        <Link to={`/createAd`} className={"createAd-button"}>
                            <span>{t('create-ad')}</span>
                        </Link>

                        <div className={"createAd-icons"}>
                            <div className={'content-messages-wrapper'}>
                                {!!+messagesCount &&
                                    <div className={'content-messages'}><span>{+messagesCount}</span></div>}
                                <Link to={`/profile/messages`}>
                                    <svg className={"message-header"} width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3 20.0001L4.3 16.1001C3.17644 14.4384 2.76999 12.4705 3.15622 10.5624C3.54244 8.65427 4.69506 6.93575 6.39977 5.72635C8.10447 4.51696 10.2453 3.89898 12.4241 3.98732C14.6029 4.07566 16.6715 4.86431 18.2453 6.20664C19.819 7.54896 20.7909 9.35362 20.9801 11.2851C21.1693 13.2165 20.563 15.1433 19.2739 16.7072C17.9848 18.2711 16.1007 19.3657 13.9718 19.7874C11.8429 20.2092 9.6142 19.9294 7.7 19.0001L3 20.0001"
                                            stroke="white" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                </Link>
                            </div>
                            <OutsideClickHandler callback={() => {
                                if (bells) {
                                    setBells(false);
                                    dispatch(setNewNotifications([]))
                                    dispatch(updateNotificationData([...currentNotificationsData, ...notificationsData]))
                                    setIsClosedByOutsideClick(true);
                                    setTimeout(() => setIsClosedByOutsideClick(false), 200);
                                }
                            }}>
                                <div className={'content-messages-wrapper'}>
                                    {!!currentNotificationsData.length &&
                                        <div className={'content-followed'}>
                                            <span>{+currentNotificationsData.length}</span></div>}
                                    <svg className={'bell'} onClick={() => {
                                        if (!isClosedByOutsideClick) {
                                            setBells((state) => !state);
                                        }
                                    }} width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10 5C10 4.46957 10.2107 3.96086 10.5858 3.58579C10.9609 3.21071 11.4696 3 12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5C15.1484 5.54303 16.1274 6.38833 16.8321 7.4453C17.5367 8.50227 17.9404 9.73107 18 11V14C18.0753 14.6217 18.2954 15.2171 18.6428 15.7381C18.9902 16.2592 19.4551 16.6914 20 17H4C4.54494 16.6914 5.00981 16.2592 5.35719 15.7381C5.70457 15.2171 5.92474 14.6217 6 14V11C6.05956 9.73107 6.4633 8.50227 7.16795 7.4453C7.8726 6.38833 8.85159 5.54303 10 5"
                                            stroke="white" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M9 17V18C9 18.7956 9.31607 19.5587 9.87868 20.1213C10.4413 20.6839 11.2044 21 12 21C12.7956 21 13.5587 20.6839 14.1213 20.1213C14.6839 19.5587 15 18.7956 15 18V17"
                                            stroke="white" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>

                                </div>
                                {bells && (
                                    <div
                                        style={(!currentNotificationsData.length && !notificationsData.length) ? {width: '250px'} : null}
                                        className={'notifications'}>
                                        <div className={'new-notifications'}>
                                                                    <span
                                                                        className={'count-notification'}>{currentNotificationsData.length} {t('new-notifications-yet')}:</span>
                                            {(currentNotificationsData.length || notificationsData.length)
                                                ? <div onClick={() => {
                                                    dispatch(updateNotificationData([]))
                                                    dispatch(setNewNotifications([]))
                                                }} className={'clear'}>
                                                    <span>{t('clear')}</span>
                                                    <svg width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16 8L8 16M8 8L16 16L8 8Z"
                                                              stroke="#94A3B8"
                                                              strokeWidth="2"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                </div> : null}
                                        </div>
                                        <div
                                            className={'notifications-content-wrapper overflow_scrollbar'}>
                                            {notificationsLayout()}
                                        </div>
                                    </div>
                                )}
                            </OutsideClickHandler>
                            <div className={'content-messages-wrapper'}>
                                {!!+followedAdsCount &&
                                    <div className={'content-followed'}><span>{followedAdsCount}</span></div>}
                                <Link to={`/profile/my-followed?page=1`}>
                                    <svg className={"heart-header"} width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.5001 13.5719L12.0001 20.9999M12.0001 20.9999L4.50006 13.5719C4.00536 13.0905 3.6157 12.5119 3.3556 11.8726C3.09551 11.2332 2.97062 10.5469 2.98879 9.85687C3.00697 9.16685 3.16782 8.48807 3.46121 7.86327C3.75461 7.23847 4.17419 6.68119 4.69354 6.22651C5.21289 5.77184 5.82076 5.42962 6.47887 5.22141C7.13697 5.01321 7.83106 4.94352 8.51743 5.01673C9.20379 5.08995 9.86756 5.30449 10.4669 5.64684C11.0663 5.98919 11.5883 6.45193 12.0001 7.00593C12.4136 6.45595 12.9362 5.99725 13.5352 5.65854C14.1341 5.31982 14.7966 5.10838 15.481 5.03745C16.1654 4.96652 16.8571 5.03763 17.5128 5.24632C18.1685 5.45502 18.7741 5.79681 19.2916 6.2503C19.8091 6.70379 20.2275 7.25922 20.5205 7.88182C20.8135 8.50443 20.9748 9.18082 20.9944 9.86864C21.0139 10.5565 20.8913 11.2409 20.6342 11.8792C20.3771 12.5174 19.991 13.0958 19.5001 13.5779L12.0001 20.9999Z"
                                            stroke="white" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>

                                </Link>
                            </div>
                        </div>
                    </>
                ) : null}

                {isLogin ? <MenuHeader/> : <Auth/>}
            </nav>
        </div>
    );
};
export default Header;
