import {setToken} from '../redux/reducers/userAuth/sliceUserAuth';
import API from './handleAPI';
import Keys from '../Constants/helper';
import compressImage from "../Constants/compressImage";

export const AuthOperations = {
    sendRecoverEmail: (data) => API().post(`/auth/password/recovery`, data),

    setRecoverPassword: (data) => API(null,false).post(`/auth/password/set`, data),

    googleLogin: (data) => API().post(`/auth/oauth/google`, data),

    facebookLogin: (data) => API().post(`/auth/oauth/facebook`, data),

    userLogout: () => API().post(`/auth/logout`),
};

export const APIPayment = {
    payCurrentPackage: (token, id) => API().post(`/payments/packages/${id}/pay`),

    topAdGetClientSecret: (token, id) => API().post(`/payments/top/${id}`),

    getPaymentsPackages: () => API().get(`/payments/packages`),
};

export const APIChat = {
    getUserConversations: (filter = 'recent', page = 1, page_size = 10) => {
        return API()
            .get(`/users/conversations`, {
                params: {
                    filter,
                    page,
                    page_size,
                },
            })
    },

    setChatReactivate: (conversation_id) => API().post(`/users/conversations/${conversation_id}/reactivate`),

    setChatMarked: (conversation_id) => API().post(`/users/conversations/${conversation_id}/mark`),

    setChatUnMarked: (conversation_id) => API().post(`/users/conversations/${conversation_id}/unmark`),

    getActiveConversation: (conversation_id) => API().get(`/users/conversations/${conversation_id}`),

    sendMessageToConversation: (conversation_id, data) => {
        const file = data.get('file')
        if (file) {
            return compressImage(file)
                .then(function (compressedFile) {
                    data.set('file', compressedFile);
                    return API().post(`/users/conversations/${conversation_id}`, data);
                })
                .catch(function (error) {
                    console.log("Cannot compress file: " + error)
                    throw error
                })
        } else {
            return API().post(`/users/conversations/${conversation_id}`, data);
        }

    },

    setConversationRead: (conversation_id) => API().post(`/users/conversations/${conversation_id}/seen`),

    deleteConversation: (conversation_id) => API().delete(`/users/conversations/${conversation_id}`),
};

export const APIUserConversations = {
    getAllPopularTags: (data) => {
        const {
            text = '', price_from, max_count = 10,
            price_to, radius, lat, lng, tags, cats
        } = data;

        let queryString = `max_count=${max_count}${text ? `&text=${text}` : ''}${price_from ? `&price_from=${price_from}` : ''}${price_to ? `&price_to=${price_to}` : ''}${radius ? `&radius=${radius}` : ''}${lat ? `&lat=${lat}` : ''}${lng ? `&lng=${lng}` : ''}${tags && tags.length ? tags.map(x => `&tags=${encodeURIComponent(x)}`).join('') : ''}${cats && cats.length ? cats.map(x => `&cats=${x}`).join('') : ''}`;
        return API().get(`/plugs/tags/popular?${queryString}`);
    },

    getTagsByPrefix: (prefix) => {
        return API().get(`/plugs/tags`, {
            params: {
                prefix,
            },
        })
    },

    setReadFlagToConversation: (adId, convId) => API().get(`/plugs/${adId}/conversations/${convId}/seen`),

    sendAnswerForCurrConv: (token, cardId, convId, mess) => {
        const data = {body: mess};
        return API()
            .post(`/payments/packages`, data)
    },

    getConversations: (id) => API().get(`/plugs/${id}/conversations`),

    sendConversationMess: (id, formData) => {
        const file = formData.get('file')
        if (file) {
            return compressImage(file)
                .then(function (compressedFile) {
                    formData.set('file', compressedFile);
                    return API().post(`/plugs/${id}/conversations`, formData);
                })
                .catch(function (error) {
                    console.log("Cannot compress file: " + error)
                    throw error
                })
        } else {
            return API().post(`/plugs/${id}/conversations`, formData);
        }
    },

    sendFeedBackMess: (id, data) => API().post(`/users/${id}/reviews`, data),

    getFeedBackMessage: (id) => API().get(`/users/${id}/reviews`),

    getUserPublicInfo: (id) => API().get(`/users/${id}/info`),
};

export const APIUserRequest = {
    userRefreshToken: (refreshToken, dispatch) => {
        return new Promise((resolve, reject) => {
            API(refreshToken)
                .post(`/auth/refresh`)
                .then((r) => {
                    const token = r.data.access_token;
                    // console.log(r)
                    localStorage.setItem(Keys.JWT_TOKEN, token);
                    if (dispatch) dispatch(setToken(token));
                    resolve(token);
                })
                .catch((e) => {
                    console.log(e);
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = '/auth?type=login';
                    // socket.disconnect()
                    reject(e);
                });
        })
    },

    getUserInfo: () => API().get(`/users/info`),

    setUserInfo: (data) => API().post(`/users/info`, data),
    getUserAddress: () => API().get(`/users/address`),

    setUserAddress: (data) => API().post(`/users/address`, data),

    setUserMain: (data) => API().post(`/users/settings/main`, data),

    getUserMain: () => API().get(`/users/settings/main`),

    setUserContact: (data) => API().post(`/users/settings/contact`, data),

    getUserContact: () => API().get(`/users/settings/contact`),

    deleteUserContact: (id) => API().delete(`/users/${id}`),

    setUserBilling: (data) => API().post(`/users/settings/billing`, data),

    getUserBilling: () => API().get(`/users/settings/billing`),

    getUserTransactions: (page = 1, page_size = 10) => API().get(`/users/transactions?page=${page}&page_size=${page_size}`),

    getUserInvoices: (page = 1, page_size = 10) => API().get(`/invoices?page=${page}&page_size=${page_size}`),

    getUserPayments: (page = 1) => {
        return API()
            .get(`/payments`, {
                params: {
                    page: page,
                },
            })
    },

    verifyUserEmail: () => API().post('/users/verify/email'),

    verifyUserEmailToken: (token) => API().get(`/users/verify/email?token=${token}`)
};

export const APICreateAdRequests = {
    createAd: (data) => API().post(`/plugs`, data),

    updateAd: (adId, data) => API().put(`/plugs/${adId}`, data),

    handleCreateAdImages: (file) => {
        let formData = new FormData();
        return compressImage(file)
            .then(function (compressedFile) {
                formData.append('file', compressedFile);
                // for (let b of formData.entries()) {
                //     console.log(b);
                // }
                return API().post(`/images`, formData)
            })
            .catch(function (error) {
                console.log("Cannot compress file: " + error)
                throw error
            })
    },
};

export const APICardRequest = {
    adToTop: (adId) => API().post(`/plugs/${adId}/top`),

    adTopSms: (adId, data) => {
        return API().post(`/plugs/${adId}/top/sms`, data)
    },

    getAllAds: (props) => {
        const {
            page = 1, page_size = 10, text = '', price_from,
            price_to, radius, lat, lng, tags, cats
        } = props;
        let queryString = `page=${page}&page_size=${page_size}${text ? `&text=${text}` : ''}${price_from ? `&price_from=${price_from}` : ''}${price_to ? `&price_to=${price_to}` : ''}${radius ? `&radius=${radius}` : ''}${lat ? `&lat=${lat}` : ''}${lng ? `&lng=${lng}` : ''}${tags && tags.length ? tags.map(x => `&tags=${encodeURIComponent(x)}`).join('') : ''}${cats && cats.length ? cats.map(x => `&cats=${x}`).join('') : ''}`;
        return API().get(`/plugs?${queryString}`)
    },

    getActiveAds: (id, page = 1, page_size = 10, archived = false, skipped_ad_id = null) => {
        let params = {
            page,
            page_size,
        };
        if (archived) {
            params.archived = archived
        }
        if (skipped_ad_id) {
            params.skipped_ad_id = skipped_ad_id
        }
        return API()
            .get(`/users/${id}/plugs`, {
                params,
            })
    },

    getSimilarAds: (id) => API().get(`/plugs/${id}/similar`),

    getFilterTitle: (text) => {
        return API()
            .get(`/plugs/suggestions`, {
                params: {
                    text,
                },
            })
    },

    getFilterAdWords: (text) => {
        return API()
            .get(`/plugs/suggestions/words`, {
                params: {
                    text,
                },
            })
    },

    getCurrentCard: (id) => API().get(`/plugs/${id}`),

    deleteCard: (id) => API().delete(`/plugs/${id}`),

    deleteReview: (user_id, review_id) => API().delete(`/users/${user_id}/reviews/${review_id}`),

    archiveCard: (id) => API().post(`/plugs/${id}/archive`),

    sendActivateArchive: (id) => API().post(`/plugs/${id}/activate`),

    followCard: (id) => API().post(`/plugs/${id}/follow`),

    getFollowedCards: (page = 1, page_size = 10) => {
        return API()
            .get(`/plugs/followed`, {
                params: {
                    page: page,
                    page_size,
                },
            })
    },

    sendStatFromCard: (id) => API().get(`/plugs/${id}/icv`),

    sendUserCompliant: (id, data) => API().post(`/plugs/${id}/complaints`, data),
};

export const APILandingRequest = {
    getCategories: () => API().get(`/plugs/categories`),

    getPromotedItems: () => API().get(`/plugs/promoted`),
};
