import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getAccessTokenSelector,
    getUserId,
    isLoginSelector
} from "../../redux/selectors/selectors";
import {Link} from "react-router-dom";
import "../detailCard/adDetail.css";
import heart from "../card/assets/heart.svg";
import phone from "../detailCard/assets/phone.svg";
import mapPin from "../card/assets/map-pin.svg";
import flag from "../detailCard/assets/flag.svg";
import eye from "../card/assets/eye.svg";
import {HeartAd} from "../heart/HeartAd";
import {Rating} from "../rating/Rating";
import Popup from "../card/PopupOwner/Popup";
import PopupComplain from "../card/PopupComplain/PopupComplain";
import {APIUserConversations} from "../../API/api";
import GoogleMap from "../../Components/GoogleMapComponents/GoogleMap";
import ChangedConfirmedPopup from "../../Components/SuccessPopup/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import {updateFollowedCount} from "../../redux/reducers/ads/sliceAds";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import placeholderImage from "../card/assets/placeholder_ad_image.svg";
import {Breadcrumbs, CircularProgress, Tooltip} from "@material-ui/core";
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import FormatColorFillOutlinedIcon from '@material-ui/icons/FormatColorFillOutlined'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import LocalGasStationOutlinedIcon from '@material-ui/icons/LocalGasStationOutlined';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import {PiPathBold} from "react-icons/pi";
import {FaChartArea} from "react-icons/fa";
import {TbStairs} from "react-icons/tb";
import {GiGearStickPattern} from "react-icons/gi";
import {MdOutlineBedroomChild} from "react-icons/md";
import ImgsViewer from "react-images-viewer";
import {numberWithDots} from "../../Constants/numberWithDots";
import {useTranslation} from 'react-i18next';
import {
    EmailShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon, TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton, PinterestShareButton, PinterestIcon
} from "react-share";
import PopupRaise from "../../pages/adsActions/MyAds/PopupRaise/PopupRaise";
import PopupPurchase from "../../pages/adsActions/MyAds/PopupPurchase/PopupPurchase";
import PopupError from "../../pages/adsActions/MyAds/PopupError/PopupError";
import {useAdCategories} from "../../cache/queries";
import {capitalize} from "lodash";
// import AdSense from "react-adsense";

const AttrIconMapping = ({attributeName}) => {
    const color = {color: "#94a3b8ff"}
    const iconMap = {
        'brand': <LocalOfferOutlinedIcon style={color}/>,
        'size': <AspectRatioOutlinedIcon style={color}/>,
        'color': <FormatColorFillOutlinedIcon style={color}/>,
        'age': <QueryBuilderOutlinedIcon style={color}/>,
        'fuel': <LocalGasStationOutlinedIcon style={color}/>,
        'vin': <DriveEtaOutlinedIcon style={color}/>,
        'transmission': <GiGearStickPattern size={24} style={color}/>,
        'price': <EuroOutlinedIcon style={color}/>,
        'floor_number': <TbStairs style={color}/>,
        'area': <FaChartArea size={24} style={color}/>,
        'mileage': <PiPathBold size={24} style={color}/>,
        'category': <CategoryOutlinedIcon style={color}/>,
        'room_count': <MdOutlineBedroomChild size={24} style={color}/>

    }
    if (iconMap.hasOwnProperty(attributeName)) {
        return iconMap[attributeName];
    } else {
        return iconMap['brand'];
    }
}

export const HelperAttrsAdding = ({attrs}) => {
    const {t} = useTranslation();
    const allowed_attrs = ['brand', 'size', 'area', 'age', 'mileage', 'fuel', 'floor_number', 'room_count'];
    const filteredAttrs = Object.fromEntries(
        Object.entries(attrs).filter(([key, value]) => {
            // console.log(key, value)
            return typeof value.value !== 'object' && allowed_attrs.includes(key);
        })
    );
    return (
        <div className={'desc_adDetail'}>
            <div className={'attrs'}>
                {/*<span className={'title'}>{t('Attributes')}:</span>*/}
                <div className={'attrs-list'}>
                    {filteredAttrs ?
                        Object.keys(filteredAttrs).map((key, index) => (
                            <div className={'name-of-attr'} key={key}>
                                <AttrIconMapping attributeName={key}/>
                                <span>{capitalize(filteredAttrs[key].value)} {filteredAttrs[key].unit}</span>
                            </div>
                        ))
                        : <div>
                            <span>{t('no-tags-yet')}...</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export const DetailCard = (props) => {
    const {t} = useTranslation();
    const {
        id,
        images,
        title,
        text,
        price,
        hits,
        liked,
        similarAds,
        setSimilarAds,
        contact,
        ownerAdsLoading,
        loc_name,
        user,
        time,
        setCurrentCard,
        a_loc,
        tags,
        cats,
        attrs,
        followed,
        ownerAds,
        setOwnerAds,
        top_date,
        paymentActive
        // reactions,
    } = props;
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const dispatch = useDispatch();
    // const {locationAddress} = useGetCurrentLocation(a_loc?.length ? a_loc[1] : null, a_loc?.length ? a_loc[0] : null);
    const isLogin = useSelector(isLoginSelector);
    const [modalActive, setModalActive] = useState(false);
    const currentUser = useSelector(getUserId);
    const isAdOwner = user.id === currentUser
    const [modalActiveComplain, setModalActiveComplain] = useState(false);
    const [showNumber, setShowNumber] = useState(false);
    const [userDetailsInfo, setUserDetailsInfo] = useState({});
    // const [latLng, setLatLng] = useState(null);
    const [detailsPopularTags, setDetailsPopularTags] = useState([]);
    const [imageViewer, setImageViewer] = useState({
        show: false,
        currImg: 0,
    });
    const [swiped, setSwiped] = useState(false);
    const [assignedCats, setAssignedCats] = useState([]);

    const token = useSelector(getAccessTokenSelector);
    const [modalActiveError, setModalActiveError] = useState(false)
    const [modalActivePurchase, setModalActivePurchase] = useState(false)
    const [modalActiveRaise, setModalActiveRaise] = useState({
        show: paymentActive && isAdOwner,
        data: props,
    });

    const {isLoading: cats_loading, data: q_categories} = useAdCategories()

    const togglePopupRaise = (data) => {
        setModalActiveRaise(prevState => ({
            show: !prevState.show,
            data,
        }))
    };

    const handleSwiped = useCallback(() => {
        setSwiped(true)
    }, [])

    const handleOnItemClick = useCallback((e) => {
        if (swiped) {
            e?.preventDefault();
            e?.stopPropagation();
            setSwiped(false)
        }
    }, [swiped])

    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 setLatLng(prevState => ({
    //                     ...prevState,
    //                     lat: position.coords.latitude,
    //                     lng: position.coords.longitude,
    //                     loaded: true,
    //                     access: true,
    //                 }))
    //             },
    //             (err) => {
    //                 console.log(err)
    //                 setLatLng({
    //                     loaded: true,
    //                     access: false
    //                 })
    //             },
    //             {
    //                 enableHighAccuracy: true,
    //                 timeout: 5000,
    //                 maximumAge: 0
    //             }
    //         )
    //     } else {
    //         setLatLng({
    //             loaded: true,
    //             access: false
    //         })
    //     }
    // }, [])

    useEffect(() => {
        if (user.id) {
            APIUserConversations.getUserPublicInfo(user.id)
                .then(res => {
                    // console.log(res)
                    setUserDetailsInfo(res.data);
                })
                .catch((e) => {
                    console.log(e);
                    return e
                });
        }
    }, [user.id]);

    // const getDistanceFromLatLonInKm = useCallback((lat1, lon1, lat2, lon2) => {
    //     if (latLng?.loaded && latLng?.access) {
    //         const deg2rad = (deg) => deg * (Math.PI / 180)
    //         let R = 6371; // Radius of the earth in km
    //         let dLat = deg2rad(lat2 - lat1);  // deg2rad below
    //         let dLon = deg2rad(lon2 - lon1);
    //         let a =
    //             Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    //             Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    //             Math.sin(dLon / 2) * Math.sin(dLon / 2)
    //         ;
    //         let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    //         return Math.ceil(R * c);
    //     }
    // }, [latLng?.access, latLng?.loaded])

    useEffect(() => {
        let data = {
            max_count: 10,
        };
        if (a_loc && a_loc.length) {
            data.lat = a_loc[1]
            data.lng = a_loc[0]
        }
        if (!!tags.length) {
            data.tags = tags
        }
        APIUserConversations.getAllPopularTags(data)
            // APIUserConversations.getPopularTags()
            .then(r => {
                // console.log(r)
                setDetailsPopularTags(r.data)
            })
    }, [tags, a_loc])

    const getAssignedCats = useCallback((category) => {
            if (!cats_loading) {
                const filteredCategories = q_categories.data?.filter(obj => obj.keywords.includes(category));
                if (filteredCategories.length > 0) {
                    return filteredCategories.map(obj => obj.name);
                } else {
                    return [];
                }
            }
        }, [q_categories, cats_loading]
    )

    useEffect(() => {
        // console.log(q_categories)
        if (!cats_loading) {
            // console.log(getAssignedCats(cats[0]))
            setAssignedCats(getAssignedCats(cats[0]))
        }
    }, [q_categories, cats, getAssignedCats, cats_loading]);

    const ArrowLeft = ({currentSlide, slideCount, ...props}) => (
        <div {...props} className={'slider_arrow_left'}>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0058 4L6.98743 8L11.0058 12L11.0058 4Z" fill="white" stroke="white" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
    const ArrowRight = ({currentSlide, slideCount, ...props}) => (
        <div {...props} className={'slider_arrow_right'}>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.99416 4L11.0126 8L6.99416 12L6.99416 4Z" fill="white" stroke="white" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );

    const sliderSettings = {
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        dots: true,
        prevArrow: <ArrowLeft/>,
        nextArrow: <ArrowRight/>,
    };

    const HelperImgAdding = ({user_id, images, className, title, onClick, style, index, lazy = true}) => {
        let src = (format, suffix = '') => images.length ? `https://d2j7v9zcmivqbu.cloudfront.net/${user_id}/${images[index === 0 || index ? index : 0]}${suffix}.${format}` : placeholderImage;
        return (
            <picture onClick={onClick} style={style}>
                <source
                    srcSet={`${src('webp', '-m')} 512w, ${src('webp')} 1024w`}
                    sizes="(max-width: 700px) 100vw, (max-width: 1024px) 50vw, 50vw"
                    type="image/webp"
                />
                <img src={src('jpeg')} alt={title}
                     className={className} {...(!lazy && {fetchpriority: "high"})} {...(lazy && {loading: "lazy"})}
                     onError={(e) => {
                         e.target.onerror = null;
                         e.currentTarget.parentNode.children[0].srcset = e.target.src;
                         e.currentTarget.parentNode.children[1].srcset = e.target.src;
                     }}/>
            </picture>
        )
    };

    const togglePopup = () => {
        if (isLogin) {
            setModalActive(!modalActive);
        } else {
            changeContinuePopup(t('you-should-sign-in-to-send-a-message-to-the-owner'))
            setTimeout(() => {
                changeContinuePopup(t('you-should-sign-in-to-send-a-message-to-the-owner'))
            }, 3000)

        }
    };

    const togglePopupComplain = () => {
        if (isLogin) {
            setModalActiveComplain(!modalActiveComplain);
        } else {
            changeContinuePopup(t('you-should-sign-in-to-leave-a-complaint'))
            setTimeout(() => {
                changeContinuePopup(t('you-should-sign-in-to-leave-a-complaint'))
            }, 3000)
        }
    };

    return [
        !!images.length &&
        <ImgsViewer
            key={0}
            imgs={images.map((x, i) => {
                return {
                    src: `https://d2j7v9zcmivqbu.cloudfront.net/${user.id}/${images[i]}.jpeg`,
                    srcSet: `https://d2j7v9zcmivqbu.cloudfront.net/${user.id}/${images[i]}.webp`,
                }
            })}
            backdropCloseable={true}
            currImg={imageViewer.currImg}
            isOpen={imageViewer.show}
            onClickPrev={() => {
                setImageViewer(prevState => ({
                    ...prevState,
                    currImg: prevState.currImg - 1,
                }))
            }}
            onClickNext={() => {
                setImageViewer(prevState => ({
                    ...prevState,
                    currImg: prevState.currImg + 1,
                }))
            }}
            onClose={() => {
                setImageViewer({
                    show: false,
                    currImg: 0,
                })
            }}
        />,
        <div key={1} className={'wrapper_ad_detail'}>
            <div className={'adDetail_img_owner'}>
                <div>
                    <div className={'img-product'}>
                        {images.length > 1
                            ? <Slider {...sliderSettings}
                                      className={'detail_ad_slider'}
                                      onSwipe={handleSwiped}>
                                {images.map((x, i) => (
                                    <div className={'image_slider_container'}
                                         onClickCapture={(e) => handleOnItemClick(e)}
                                         key={i}>
                                        <HelperImgAdding user_id={user.id} images={images}
                                                         index={i}
                                                         onClick={() => {
                                                             if (!!images.length) {
                                                                 setImageViewer({
                                                                     show: true,
                                                                     currImg: 0,
                                                                 })
                                                             }
                                                         }}
                                                         title={title}
                                                         style={!images.length ? {cursor: 'auto'} : null}
                                                         className={'product'}
                                                         lazy={!(i === 0)}
                                        />
                                    </div>
                                ))}
                            </Slider>
                            : <HelperImgAdding user_id={user.id}
                                               images={images}
                                               onClick={() => {
                                                   if (!!images.length) {
                                                       setImageViewer({
                                                           show: true,
                                                           currImg: 0,
                                                       })
                                                   }
                                               }}
                                               title={title}
                                               style={!images.length ? {cursor: 'auto'} : null}
                                               className={'product'}
                                               lazy={false}
                            />
                        }
                        {top_date && (
                            <div className={"details_top_date"}>
                                <span>TOP</span>
                            </div>
                        )}
                    </div>
                    <Breadcrumbs separator="›" aria-label="breadcrumb" className={'cat-link'}>
                        <Link
                            to={`/ads?page=1`}>
                            <p>{capitalize(t('active-ads'))}</p>
                        </Link>
                        <Link
                            to={`/ads?page=1&category=${assignedCats[0]?.replace(/ /g, '-')}`}>
                            <p>{capitalize(t(assignedCats[0]))}</p>
                        </Link>
                    </Breadcrumbs>
                    <HelperAttrsAdding attrs={attrs}/>
                    <div className={'desc-product'}>
                        <span className={'title'}>{t('description')}:</span>
                        <p className={'title-desc'}>{text}</p>
                        <div className={'complain-and-likes'}>
                            <div className={'complain'} onClick={togglePopupComplain}>
                                <img src={flag} className={'flag'} alt={'flag'}/>
                                <span>{t('complain')}</span>
                            </div>
                            <div className={'complain'}>
                                <FacebookShareButton
                                    url={window.location.href}>
                                    <FacebookIcon className={'flag'} size={30} round/>
                                </FacebookShareButton>
                                <EmailShareButton
                                    url={window.location.href}>
                                    <EmailIcon className={'flag'} size={30} round/>
                                </EmailShareButton>
                                <WhatsappShareButton
                                    url={window.location.href}>
                                    <WhatsappIcon className={'flag'} size={30} round/>
                                </WhatsappShareButton>
                                <TwitterShareButton
                                    url={window.location.href}>
                                    <TwitterIcon className={'flag'} size={30} round/>
                                </TwitterShareButton>
                                <PinterestShareButton url={window.location.href}
                                                      media={`https://d2j7v9zcmivqbu.cloudfront.net/${user.id}/${images[0]}.webp`}>
                                    <PinterestIcon className={'flag'} size={30} round/>
                                </PinterestShareButton>
                            </div>

                            <div className={"likes-and-view"}>
                                <div className={"views"}>
                                    <img src={eye} alt={"view"}/>
                                    <span>{hits}</span>
                                </div>
                                <div className={"likes"}>
                                    <img src={heart} alt={"heart"} className={'detail-heart'}/>
                                    <span>{followed}</span>
                                </div>
                            </div>
                        </div>

                        {modalActiveComplain && (
                            <PopupComplain
                                active={modalActiveComplain}
                                setActive={setModalActiveComplain}
                                adId={id}
                                changeContinuePopup={changeContinuePopup}
                            />
                        )}
                    </div>
                </div>

                <div className={"desc_adDetail"}>
                    <div className={"desc_owner"}>
                        <span className={"owner-word"}>{t('owner')}</span>
                        <Tooltip title={`${t('open')} ${user.name} ${t('public-profile')}`} placement={'top'}
                                 arrow>
                            <Link to={`/user-profile/${user.id}?page=1`} className={"owner-rating"}>
                                <div className={"details_default"}>
                                    {user.avatar
                                        ? <img src={user.avatar} alt={"user"} className={"user-photo"}/>
                                        : <span>{user.name.split("")[0]}</span>}
                                </div>
                                <div className={"rating-and-name"}>
                                    <span>{user.name}</span>
                                    <Rating size={20} value={userDetailsInfo.rating}/>
                                </div>
                            </Link>
                        </Tooltip>
                    </div>
                    <div className={"price-and-contacts"}>
                        <div className={"time-and-title"}>
                            <div style={{display: "grid", gridGap: "6px"}}>
                                <span className={"time"}>{time.split("T")[0]}</span>
                                <h1 className={"title"}>{title}
                                </h1>
                            </div>
                            {isAdOwner && isLogin ?
                                <Tooltip title={t('Raise Ad to TOP')} placement={'top'}
                                         arrow>
                                    <button
                                        className={"raise-button"}
                                        onClick={() => togglePopupRaise(props)}>
                                        <span className={"top"}>{t('Raise Ad')}</span>
                                    </button>
                                </Tooltip>
                                : null
                            }
                            {isLogin ?
                                <div className={"favourite"}>
                                    <HeartAd item={{id: id, liked: liked}}
                                             handleFollowedItem={(r) => {
                                                 dispatch(updateFollowedCount(r.message === 'following'))
                                                 setCurrentCard(prevState => ({
                                                     ...prevState,
                                                     liked: r.message === 'following'
                                                 }))
                                             }}
                                    />
                                </div> : null}
                        </div>
                        <div style={{margin: "1.5rem 0 1.5rem"}}>
                            <span className={"price"}>{numberWithDots(price)} &#8364;</span>
                        </div>
                        {/*{loc_name && (!latLng?.access || !(!!a_loc && !!a_loc.length)) ?*/}
                        {/*    <div className={'location-no-access'}>*/}
                        {/*        <div className={'map-and-location'}>*/}
                        {/*            <div className={'user_geolocation'}>*/}
                        {/*                <img src={mapPin} alt={'map'} className={'map-pin'}/>*/}
                        {/*                <a rel="noreferrer"*/}
                        {/*                   href={`https://www.google.com/maps/place/${loc_name}`}*/}
                        {/*                   target={'_blank'}><p>{loc_name}</p></a>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div> : null}*/}
                        <div className={"message-and-number"}>
                            {user.id !== currentUser ? <div className={"message"} onClick={togglePopup}>
                                <span>{t('send-a-message-to-the-owner')}</span>
                            </div> : null}
                        </div>
                        {contact && !!contact.number
                            ? <div className={'phone-number'} onClick={() => {
                                if (contact && !!contact.number) setShowNumber(!showNumber)
                            }}>
                                {!showNumber && (
                                    <div className={'show'}>
                                        <img src={phone} alt={'phone'} className={'phone'}/>
                                        <span>{t('show-phone-number')}</span>
                                    </div>
                                )}
                                {showNumber && (
                                    <span>+{contact.number}</span>
                                )}
                            </div> : null}

                        {isLogin && modalActive && (
                            <Popup
                                active={modalActive}
                                setActive={setModalActive}
                                user={user}
                                id={id}
                                changeContinuePopup={changeContinuePopup}
                            />
                        )}
                    </div>

                    {/*{loc_name && (!latLng?.access || !(!!a_loc && !!a_loc.length)) ?*/}
                    {/*    <div className={'location-mob'}>*/}
                    {/*        <div className={'map-and-location'}>*/}
                    {/*            <span className={'title'}>Location:</span>*/}
                    {/*            <div className={'user_geolocation'}>*/}
                    {/*                <img src={mapPin} alt={'map'} className={'map-pin'}/>*/}
                    {/*                <a rel="noreferrer"*/}
                    {/*                   href={`https://www.google.com/maps/place/${loc_name}`}*/}
                    {/*                   target={'_blank'}><p>{loc_name}</p></a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div> : null}*/}
                    {a_loc && !!a_loc.length
                        ? <div className={'location'}>
                            <div className={'map-and-location'}>
                                <span className={'title'}>{t('location')}:</span>
                                {loc_name ? <div className={'user_geolocation'}>
                                    <img src={mapPin} alt={'map'} className={'map-pin'}/>
                                    <a rel="noreferrer"
                                       href={`https://www.google.com/maps/place/${loc_name}`}
                                       target={'_blank'}>
                                        <p>{loc_name}</p></a>
                                </div> : null}
                            </div>
                            {/*{latLng?.loaded ? <GoogleMap lat={a_loc[1]} lng={a_loc[0]}/> : null}*/}
                            <GoogleMap lat={a_loc[1]} lng={a_loc[0]}/>
                            {/*{latLng?.access && latLng?.loaded ? <span*/}
                            {/*    className={'km'}>{getDistanceFromLatLonInKm(latLng.lat, latLng.lng, a_loc[1], a_loc[0])} km from you</span> : null}*/}
                        </div> : null}
                    {/*placeholder for attributes*/}
                    <div className={'tags'}>
                        <span className={'title'}>{t('tags')}:</span>
                        <div className={'tags-filter'}>
                            {tags.filter(x => x).length ?
                                tags.map((tag, name) => (
                                    <Link to={`/ads?page=1&tag=${encodeURIComponent(tag)}`} className={'name-of-filter'}
                                          key={name}>
                                        <span>{tag}</span>
                                    </Link>
                                ))
                                : <div>
                                    <span>{t('no-tags-yet')}...</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/*<div>*/}
            {/*    <AdSense.Google client={"ca-pub-8662670539367737"} slot={"4289835468"} layoutKey="-h4-n+3r-dc+ge"*/}
            {/*                    format='auto' responsive={'true'}></AdSense.Google>*/}
            {/*</div>*/}
            <div className={'ownersAd-similarAd'}>
                <div className={'owner-ads'} style={ownerAdsLoading ? {minHeight: '200px'} : null}>
                    <span className={'title'}>{t('other-owner-ads')}:</span>
                    <div className={'ad-blocks'}>
                        {!ownerAdsLoading
                            ? ownerAds.length
                                ? ownerAds.map((owner, index) => (
                                    <div className={'block-a'}
                                         key={index}>
                                        <Link to={`/ads/detail-ad/${owner.id}/${owner.slug ?? ''}`}>
                                            <HelperImgAdding user_id={owner.user.id}
                                                             images={owner.images}
                                                             title={owner.title}
                                                             className={'product-img'}/>
                                            {owner.top_date && (
                                                <div className={"owner_top_date"}>
                                                    <span>TOP</span>
                                                </div>
                                            )}
                                        </Link>

                                        <div className={'date-and-price'}>
                                            <div style={{display: 'grid', gridGap: '8px'}}>
                                                <span className={'date-ad'}>{owner.time.split("T")[0]}</span>
                                                {/*{!cats_loading && <Link*/}
                                                {/*    to={`/ads?page=1&category=${getAssignedCats(owner.cats[0])[0]?.replace(/ /g, '-')}`}>*/}
                                                {/*    <span*/}
                                                {/*        className={'date-ad'}>{capitalize(t(getAssignedCats(owner.cats[0])[0]))}</span>*/}
                                                {/*</Link>}*/}
                                            </div>
                                            {isLogin ?
                                                <div className={'favourite'}>
                                                    <HeartAd item={{id: owner.id, liked: owner.liked}}
                                                             handleFollowedItem={(r) => {
                                                                 dispatch(updateFollowedCount(r.message === 'following'))
                                                                 setOwnerAds(prevState => ([
                                                                     ...prevState.slice(0, index),
                                                                     {...owner, liked: r.message === 'following'},
                                                                     ...prevState.slice(index + 1),
                                                                 ]))
                                                             }}
                                                    />
                                                </div>
                                                : null}
                                        </div>
                                        <div className={'block-inf'}>
                                            <span className={'title'}>{owner.title}</span>
                                            <Link to={`/user-profile/${owner.user.id}?page=1`}>
                                                <div className={'views'}>
                                                    <img src={user.avatar} alt={'map'} className={'user-avatar'}/>
                                                    <span>{owner.user.name}</span>
                                                </div>
                                            </Link>
                                            <div className={'loc_block_inf'}>
                                                <img src={mapPin} alt={'map'} className={'map-pin'}/>
                                                <p>{owner.loc_name}</p>
                                            </div>
                                            <div>
                                                <div className={'likes-and-view'}>
                                                    <div className={'views'}>
                                                        <img src={eye} alt={'view'}/>
                                                        <span>{owner.hits}</span>
                                                    </div>
                                                    <div className={'likes'}>
                                                        <span>{owner.reactions}</span>
                                                        <img src={heart} alt={'heart'} className={'likes-heart'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : <p>{t('no-other-owner-ads')}...</p>
                            : <CircularProgress
                                style={{position: "absolute", top: "40%", left: "50%"}}
                                size={40}
                            />}
                    </div>
                </div>
                <div className={'similar-ads'}>
                    <span className={'title'}>{t('similar-ads')}:</span>
                    {similarAds.length ? (
                        <div className={'ad-blocks-similar'}>
                            {similarAds.map((similar, index) => (
                                <div className={'block-a'} key={index}>
                                    {/*<div className={"similar_top_wrapper"}>*/}
                                    <Link to={`/ads/detail-ad/${similar.id}/${similar.slug ?? ''}`}
                                          className={"similar_top_wrapper"}>
                                        <HelperImgAdding user_id={similar.user.id}
                                                         images={similar.images}
                                                         title={similar.title}
                                                         className={'product-img'}/>
                                        {similar.top_date && (
                                            <div className={"similar_top_date"}>
                                                <span>TOP</span>
                                            </div>
                                        )}
                                    </Link>

                                    {/*</div>*/}
                                    <div className={'date-and-price'}>
                                        <div style={{display: 'grid', gridGap: '8px'}}>
                                            <span className={'date-ad'}>{similar.time.split("T")[0]}</span>
                                            {/*{!cats_loading && <Link*/}
                                            {/*    to={`/ads?page=1&category=${getAssignedCats(similar.cats[0])[0]?.replace(/ /g, '-')}`}>*/}
                                            {/*        <span*/}
                                            {/*            className={'date-ad'}>{capitalize(t(getAssignedCats(similar.cats[0])[0]))}</span>*/}
                                            {/*</Link>}*/}
                                            <span className={'price'}>{numberWithDots(similar.price)} &#8364;</span>
                                        </div>
                                        {isLogin ?
                                            <div className={'favourite'}>
                                                <HeartAd item={{id: similar.id, liked: similar.liked}}
                                                         handleFollowedItem={(r) => {
                                                             dispatch(updateFollowedCount(r.message === 'following'))
                                                             setSimilarAds(prevState => ([
                                                                 ...prevState.slice(0, index),
                                                                 {...similar, liked: r.message === 'following'},
                                                                 ...prevState.slice(index + 1),
                                                             ]))
                                                         }}
                                                />
                                            </div> : null}
                                    </div>
                                    <div className={'block-inf'}>
                                        <span className={'title'}>{similar.title}</span>
                                        <Link to={`/user-profile/${similar.user.id}?page=1`}>
                                            <div className={'views'}>
                                                <img src={similar.user.avatar} alt={'map'} className={'user-avatar'}/>
                                                <span>{similar.user.name}</span>
                                            </div>
                                        </Link>
                                        <div className={'views'}>
                                            <img src={mapPin} alt={'map'} className={'map-pin'}/>
                                            <p>{similar.loc_name}</p>
                                        </div>
                                        <div>
                                            <div className={'likes-and-view'}>
                                                <div className={'views'}>
                                                    <img src={eye} alt={'view'}/>
                                                    <span>{similar.hits}</span>
                                                </div>
                                                <div className={'likes'}>
                                                    <span>{similar.reactions}</span>
                                                    <img className={'likes-heart'} src={heart} alt={'heart'}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : <p>{t('no-similar-ads-for-this-ad')}...</p>}
                </div>
            </div>
            <div className={'searched-for'}>
                <span className={'title'}>{t('also-often-searched-for')}:</span>
                {/*<Breadcrumbs separator="›" aria-label="breadcrumb" className={'cat-link'}>*/}
                {/*    <Link*/}
                {/*        to={`/ads?page=1&category=${assignedCats[0]}`}>*/}
                {/*        <p>{capitalize(t('active-ads'))}</p>*/}
                {/*    </Link>*/}
                {/*    <Link*/}
                {/*        to={`/ads?page=1&category=${assignedCats[0]}`}>*/}
                {/*        <p>{capitalize(t(assignedCats[0]))}</p>*/}
                {/*    </Link>*/}
                {/*</Breadcrumbs>*/}
                <div className={"also_search tags-filter"}>
                    {detailsPopularTags.length ?
                        detailsPopularTags.map((i, index) => (
                            <Link to={`/ads?page=1&tag=${i.name}`} className={"name-of-filter"} key={index}>
                                <span>{i.name}</span>
                            </Link>
                        )) : <div>
                            <span>{t('no-tags')}</span>
                        </div>
                    }
                </div>
            </div>
        </div>,
        <ChangedConfirmedPopup successText={successText}
                               warning={!isLogin}
                               key={2}
                               changingConfirmed={changingConfirmed}/>,
        modalActiveRaise.show && (
            <PopupRaise
                key={3}
                modal={modalActiveRaise}
                setModal={setModalActiveRaise}
                token={token}
                setModalActivePurchase={setModalActivePurchase}
                setModalActiveError={setModalActiveError}
                // id={modal.data.id}
            />
        ),
        <ChangedConfirmedPopup successText={successText}
                               key={5}
                               changingConfirmed={changingConfirmed}/>,

        modalActivePurchase &&
        <PopupPurchase key={6} active={modalActivePurchase} setActive={setModalActivePurchase}/>,

        modalActiveError && <PopupError key={7} active={modalActiveError} setActive={setModalActiveError}/>,
    ]
};