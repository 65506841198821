import React, {useEffect} from "react";
import ProviderAuth from "./routers/ProviderAuth";
import {LoginAcc} from "./pages/authentication/LoginAcc/LoginAcc";
import {
    Switch,
    Redirect,
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getUserDataThunk, setIsLoginUser,
} from "./redux/reducers/userAuth/sliceUserAuth";
import PublicRouter from "./routers/PublicRouter";
import {isLoginSelector} from "./redux/selectors/selectors";
import ScrollToTop from "./Constants/ScrollToTop";
import ResetPassword from "./pages/authentication/ResetPassword";
import PrivateRoute from "./routers/PrivateRouter";
import ProfileNavigation from "./pages/Profile/ProfileNavigation";
import PrivateRouterNavigation from "./routers/PrivateRouterNavigation";
import PageNotFound from './Components/pageNotFound/PageNotFound'
import Keys from "./Constants/helper";
import {ThemeProvider, createTheme} from "@material-ui/core/styles";
import './i18n/config';
import EmailVerification from "./pages/authentication/EmailVerification";
import {HelmetProvider} from "react-helmet-async";
import Header from "./pages/header";
import Footer from "./pages/footer/Footer";

export const createAd = "/createAd";

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Inter',].join(','),
    },
})

const App = () => {
    const isLogin = useSelector(isLoginSelector);
    const dispatch = useDispatch();

    const appHeight = () => {
        document.documentElement.style.setProperty(
            "--app-height",
            `${window.innerHeight}px`
        );
    };

    useEffect(() => {
        appHeight();
        window.addEventListener("resize", appHeight);
        return () => window.removeEventListener("resize", appHeight);
    }, []);

    useEffect(() => {
        let refreshToken = localStorage.getItem(Keys.REFRESH_TOKEN);
        if (refreshToken) {
            dispatch(setIsLoginUser());
        }
    }, [dispatch]);

    useEffect(() => {
        if (isLogin) {
            dispatch(getUserDataThunk())
        }
    }, [isLogin, dispatch]);

    return (
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <ProviderAuth>
                    <Router>
                        <ScrollToTop/>
                        <Header/>
                        <Switch>
                            <Route exact path={"/auth"} component={LoginAcc}/>
                            <Route exact path={"/reset-password"} component={ResetPassword}/>
                            <Route exact path={"/verify-email"} component={EmailVerification}/>
                            <Route exact path={"/"} component={PublicRouter}/>
                            <Route exact path={"/ads"} component={PublicRouter}/>
                            <Route exact path={"/pricing"} component={PublicRouter}/>

                            <Route
                                exact
                                path={"/ads/detail-ad/:id/:advertising?"}
                                component={PublicRouter}
                            />
                            <Route exact path={"/user-profile/:id"} component={PublicRouter}/>
                            <Route exact path={"/privacy-policy"} component={PublicRouter}/>
                            <Route exact path={"/terms-of-use"} component={PublicRouter}/>
                            <Route exact path={"/createAd"} component={PrivateRouterNavigation}/>
                            <Route
                                exact
                                path={"/ads/edit-ad/:id"}
                                component={PrivateRouterNavigation}
                            />

                            <PrivateRoute path={"/profile/:slug"}>
                                {/*<WrapperApp>*/}
                                <ProfileNavigation/>
                                {/*</WrapperApp>*/}
                            </PrivateRoute>

                            <Route path="*">
                                <PageNotFound/>
                                <Redirect to={"/404"}/>
                            </Route>
                        </Switch>
                        <Footer/>
                    </Router>
                </ProviderAuth>
            </ThemeProvider>
        </HelmetProvider>
    );
};

// export const WrapperApp = ({children}) => {
//     return (
//         <div className={"wrapper_app"}>
//             <Header/>
//             {children}
//             <Footer/>
//         </div>
//     );
// };

export default App;
