import axios from "axios";
import Keys from "../Constants/helper";
import {APIUserRequest} from "./api";

let MAX_RETRY = 3

const API = (token = null, retry=true) => {
    const userToken = localStorage.getItem(Keys.JWT_TOKEN);
    const refreshToken = localStorage.getItem(Keys.REFRESH_TOKEN);
    const baseURL = process.env.REACT_APP_API_URL;
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    if (token ?? userToken) {
        headers.Authorization = "Bearer " + (token ?? userToken);
    }

    let api = axios.create({
        baseURL,
        headers,
        timeout: 60000,
    });

    api.interceptors.request.use((request) => requestHandler(request));

    api.interceptors.response.use(
        (response) => successHandler(response),
        (error) => errorHandler(error)
    );

    const successHandler = (response) => {
        MAX_RETRY = 3
        return response
    };
    const requestHandler = (request) => request;

    const errorHandler = async (error) => {
        if (error?.response?.data?.message === "Token incorrect, supplied or malformed") {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/auth?type=login';
            return Promise.reject({...error});
        } else if (error?.response?.status === 401) {
            console.log(error.response)
            if (userToken && retry && MAX_RETRY > 0) {
                MAX_RETRY--
                console.log(MAX_RETRY)
                let newToken = await APIUserRequest.userRefreshToken(refreshToken);
                if (newToken) {
                    // console.log('retry the same request after refresh')
                    error.config.headers.Authorization = 'Bearer ' + newToken
                    // console.log('New token, :', error.config.headers.Authorization)
                    return api.request(error.response.config);
                }
            }
            console.log(error)
            return Promise.reject({...error});
        } else if (error?.response?.status === 404) {
            console.log(error);
            // debugger
            // window.location = "/404"
            return Promise.reject({...error});
        } else {
            console.log(error);
            // debugger
            return Promise.reject({...error});
        }
    };
    return api;
};

export default API;
